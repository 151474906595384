import axios from 'axios';
import { apiConfig } from '../config';
// const history = createBrowserHistory();
const axiosInstance = axios.create({
  baseURL: apiConfig.apiBaseURL
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error || !error.response || error.response.status === 403) {
      console.log('invalid');
      localStorage.removeItem('accessToken');
      window.location.reload(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
