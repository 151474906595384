import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      Transport: 'Transport BFT',
      'Order info': 'Order info',
      'Packages for transport': 'Packages for transport',
      'Customer Information': 'Customer Information',
      'There is no table data': 'There is no table data',
      Shop: 'Shop',
      'Order Number': 'Ordernr',
      'Buyer City': 'Buyer City',
      'Buyer Name': 'Buyer Name',
      Remark: 'Remark',
      Pakkeline: 'packnr',
      Weight: 'Weight',
      Volumn: 'Volume',
      'Package Id': 'PackageID',
      Description: 'Description',
      Delivername: 'Name',
      Deliverpostal: 'ZIP',
      Order: 'Order',
      Days: 'Days',
      day: 'day',
      plurals: 's',
      'Add Package': 'Add Package',
      'Print Labels': 'Print Labels',
      'Re-print Single Label': 'Re-print Single Label',
      Clear: 'Clear',
      'Text on all labels for this order': 'Text on all labels for this order'
    }
  },
  dk: {
    translation: {
      Transport: 'Transport BFT',
      'Order info': 'Ordre oplysninger',
      'Packages for transport': 'Pakker til forsendelse',
      'Customer Information': 'Kundeoplysninger',
      'There is no table data': 'Der er ingen label data',
      Shop: 'Butik',
      'Order Number': 'Ordre',
      'Buyer City': 'Leveringsby',
      'Buyer Name': 'Leveringsnavn',
      Remark: 'Bemærkning',
      Pakkeline: 'pakkenr',
      Weight: 'Vægt',
      Volumn: 'Volumen',
      'Package Id': 'Pakkelabel',
      Description: 'Beskrivelse',
      Delivername: 'Navn',
      Deliverpostal: 'Postkode',
      Order: 'Ordre',
      Days: 'Dage',
      day: 'dag',
      plurals: 'e',
      'Add Package': 'Tilføj pakke',
      'Print Labels': 'Udskriv labels',
      'Re-print Single Label': 'Udskriv ekstra label',
      Clear: 'Clear',
      'Text on all labels for this order': 'Text on all labels for this order'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'dk',
  fallbackLng: 'dk',
  interpolation: {
    escapeValue: false
  }
});
